import React from 'react';

const DonationsButton = () => (
  <>
    <div>
      <a
        href="https://www.paypal.com/donate/?hosted_button_id=GPNK9HQK2FU9E"
        className="donations-button"
      >
        ❤️ Donate
      </a>
    </div>
  </>
);

export default DonationsButton;